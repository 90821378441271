import { Resolvable } from 'ln/components/Resolve';
import Model from 'ln/model/Model';

interface AssetModel {
    file_name: string;
    preset: string;
    ext: string;
}

export default class ResourceViewModel extends Model implements Resolvable {

    cover:AssetModel;
    previewimage:AssetModel;

    public constructor( json ) {
        super(json);
    }

    public openResource( goToUrl:string ) {
        window.location.href = goToUrl;
    }

    public get title(): string {
        return this.get<string>('title');
    }

    public get description(): string {
        return this.get<string>('description');
    }

    public get shortdescription(): string {
        return this.get<string>('shortdescription');
    }

    public get level(): string[] {
        return this.get<string[]>('level');
    }

    public get topic(): string[] {
        return this.get<string[]>('topic');
    }

    public get duration(): string {
        return this.get<string>('duration');
    }

    public getViewName(): string{
        return 'Resource';
    }
    
} 