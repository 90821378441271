import register from 'ln/setup/register';
import Template from 'ln/view/Template';

import { Image as StaticImage }  from '@lernetz/ts-lib/src/components/Image';
import Repeat from 'ln/components/Repeat';
import Resolve from 'ln/components/Resolve';
import Image from './util/Image';

import { TagFilter as TagFilterTemplate } from '../templates/components/filter';
import { Tag as TagTemplate } from '../templates/components/tag';
import Resource from "./resources/Resource";
import ResourceViewModel from "./resources/ResourceViewModel";
import { SinglePageViewModel } from "./singlepage/SinglePageViewModel";
import Title from "./content-elements/title/Title";
import TitleViewModel from "./content-elements/title/TitleViewModel";
import FrageText from "./content-elements/frage-text/FrageText";
import FrageTextViewModel from "./content-elements/frage-text/FrageTextViewModel";
import Paragraph from "./content-elements/paragraph/Paragraph";
import ParagraphViewModel from "./content-elements/paragraph/ParagraphViewModel";
import ImageViewModel from "./content-elements/image/ImageViewModel";
import {Image as imageTemplate } from "templates/components/content-elements/image";
import ToolTip from "./content-elements/tool-tip/ToolTip";
import ToolTipViewModel from "./content-elements/tool-tip/ToolTipViewModel";
import VideoHosted from "./content-elements/video-hosted/VideoHosted";
import VideoHostedViewModel from "./content-elements/video-hosted/VideoHostedViewModel";
import VideoLinked from "./content-elements/video-linked/VideoLinked";
import VideoLinkedViewModel from "./content-elements/video-linked/VideoLinkedViewModel";
import IFrame from "./content-elements/i-frame/IFrame";
import IFrameViewModel from "./content-elements/i-frame/IFrameViewModel";
import { ChoiceQuiz, TextChoice } from '@lernetz/lernfragen/components/choice-quiz';
import { createClozeQuizFromText } from '@lernetz/lernfragen/components/cloze-quiz';
import { HotSpotQuiz, RectangularHotSpotArea } from '@lernetz/lernfragen/components/hot-spot-quiz';
import { DragAndDropQuiz as DragAndDropQuizViewModel, DragAndDropQuizView, DropArea, DropAreaView, LabelledDropArea as LabelledDropAreaViewModel } from '@lernetz/lernfragen/components/drag-and-drop-quiz';
import { DraggableTextItemViewModel } from "./quiz-presenter/DraggableTextItemViewModel";
import { DragAndDropQuiz , DraggableTextItem, LabelledDropArea } from "templates/components/quiz-presenter";
import { QuizPresenterViewModel } from "./quiz-presenter/QuizPresenterViewModel";
import { QuizPresenter as QuizPresenterTemplate } from "templates/components/quiz-presenter";
import { PoolAppViewModel, DetailAppViewModel } from "./app/AppViewModel";
import { PoolApp, DetailApp } from 'templates/components/app';
import ResourceDetail from "./resources/ResourceDetail";
import { SinglePage } from "templates/components/singlepage";
import { PoolViewModel } from "./pool/PoolViewModel";
import Pool from './pool/Pool';

const {viewRegistrations} = register(({model, view}) => {

    view( 'StaticImage', StaticImage );
    view( 'repeat', Repeat );
    view( 'resolve', Resolve );
    view( 'Image', Image );

    view('PoolApp', {
        template : PoolApp,
        getData : ()=> new PoolAppViewModel(),
        registrations : {
            "Resource": Resource
        }
    });

    view('DetailApp', {
        template : DetailApp,
        getData : ()=> new DetailAppViewModel(),
        registrations : {
            "Resource": ResourceDetail,
            "SinglePage": { template: SinglePage  }
        }
    });

    model( 'App\\Pool', PoolViewModel);
    view('Pool', Pool);

    view( 'TagFilter', { template: TagFilterTemplate } );
    view( 'Tag', { template: TagTemplate } );
    view( 'Resource', Resource );
    model( 'App\\InteractiveWorksheet', ResourceViewModel);
    model( 'App\\SinglePage', SinglePageViewModel);

    model( 'App\\Title', TitleViewModel).view(Title);
    view( 'Title', Title );

    model( 'App\\FrageText', FrageTextViewModel).view(FrageText);
    view( 'FrageText', FrageText );

    model( 'App\\Paragraph', ParagraphViewModel );
    view( 'Paragraph', Paragraph );

    model( 'App\\Image', ImageViewModel).view({ template: imageTemplate });
    view( 'ImageElement', {
        template: imageTemplate,
    });

    model( 'App\\Tooltip', ToolTipViewModel);
    view( 'ToolTip', ToolTip);

    model( 'App\\HostedVideo', VideoHostedViewModel);
    view('VideoHosted', VideoHosted);

    model( 'App\\LinkedVideo', VideoLinkedViewModel);
    view('VideoLinked', VideoLinked);

    model( 'App\\IFrame', IFrameViewModel);
    view('IFrame', IFrame);

    model( 'App\\ChoiceAnswer', (json:any) => new TextChoice( {  text:json.text, correct:json.correct || false }));
    model('App\\SingleChoice', (json:any) => {
        const quiz = new ChoiceQuiz({ type: 'single', choices: json.answers || [] });
        return new QuizPresenterViewModel(quiz, json.title, json.text, json.posFeedback, json.negFeedback );
    });
    model('App\\MultipleChoice', (json:any) => {
        const quiz = new ChoiceQuiz({ type: 'multiple', choices: json.answers || [] });
        return new QuizPresenterViewModel(quiz, json.title, json.text, json.posFeedback, json.negFeedback );
    });

    model('App\\DropDown', (json:any) => {
        const quiz = createClozeQuizFromText(json.text);
        return new QuizPresenterViewModel(quiz, json.title, json.question, json.posFeedback, json.negFeedback );
    });

    model(LabelledDropAreaViewModel).view(() => new class extends DropAreaView {
        constructor() {
            super(LabelledDropArea);
        }
    });

    model(DragAndDropQuizViewModel).view(() => new class extends DragAndDropQuizView {
        constructor() {
            super();
            this.template = new Template(DragAndDropQuiz);
        }
    });

    model('App\\DragDrop', (json:any) => {

        const dropAreas = [];
        json.drops.forEach( drop => {
            const dropArea = new LabelledDropAreaViewModel({ items: [], label: drop.text });
            dropAreas.push(dropArea);
        });

        const dragItems = [];

        json.drags.forEach( drag => {
            const dropAreaIndex = Number(drag.target || 0) - 1;
            const correctDropArea = 0 <= dropAreaIndex && dropAreaIndex < dropAreas.length ? dropAreas[dropAreaIndex] : null;
            const draggableTextItem = new DraggableTextItemViewModel({ image: drag.image, text: drag.text  }, correctDropArea);
            dragItems.push(draggableTextItem);
        });

        // Define a drop area in which all items are initially placed:
        const pool = new DropArea({
            items: dragItems,
        });
        dropAreas.unshift(pool);

        // Use the above drop areas when defining the quiz:
        const dragAndDropQuiz = new DragAndDropQuizViewModel({
            dropAreas: dropAreas,
            getCorrectDropAreaFor(item: DraggableTextItemViewModel) {
                return item.correctDropArea || pool;
            }
        });

        return new QuizPresenterViewModel(dragAndDropQuiz, json.title, json.text, json.posFeedback, json.negFeedback );


    });

    view(DraggableTextItemViewModel, { template: DraggableTextItem });

    model('App\\Hotspot', (json:any) => {
        const background = new ImageViewModel(json);
        const hotSpotAreas = json.hotspot_areas.map(hsa => new RectangularHotSpotArea( {  topLeft: { x: hsa.left, y: hsa.top }, bottomRight: { x:  hsa.left + hsa.width, y: hsa.top + hsa.height } }));
        const quiz = new HotSpotQuiz({ background , hotSpotAreas, spots: [] }, json);

        return new QuizPresenterViewModel(quiz, json.title, json.text, json.posFeedback, json.negFeedback );
    });

    view('QuizPresenter', { template: QuizPresenterTemplate });

    view( 'default', {
        template: '<div class="coming-soon"><h3 class="title">COMING SOON...</h3>Der Typ <strong>"[[modelName]]"</strong> kann noch nicht dargestellt werden.</div>'
    });
});

export {viewRegistrations};
