import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

import VideoHostedViewModel from './VideoHostedViewModel';
import {VideoHosted as template} from '../../../templates/components/content-elements/video-hosted';

export default class VideoHosted extends View<VideoHostedViewModel> {

    public constructor() {
        super({ template });
    }

    protected init() {
        
        if(this.data.poster) {
            const video = this.node.querySelector('video');
            video.setAttribute('poster', renderer.context.imagePath(this.data.poster, 'medium', 'png'));
        }

        const videoSource = this.node.querySelector('video > source');
        videoSource.setAttribute('src', renderer.context.imagePath(this.data.video, 'original', 'mp4'));

    }
};
