import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

import VideoLinkedViewModel from './VideoLinkedViewModel';
import {VideoLinked as template} from '../../../templates/components/content-elements/video-linked';

export default class VideoLinked extends View<VideoLinkedViewModel> {

    public constructor() {
        super({ template });
    }

    protected init() {

        const videoIframe = this.node.querySelector('iframe');
        videoIframe.setAttribute('src', this.data.url);

    }
};
