import Model from 'ln/model/Model';
import ResourceViewModel from 'components/resources/ResourceViewModel';
import TagFilterModel from 'components/filter/TagFilterModel';

export class PoolViewModel extends Model {


    topicTagFilter:TagFilterModel;
    durationTagFilter:TagFilterModel;
    levelTagFilter:TagFilterModel;

    displayedResources:ResourceViewModel[];

    public constructor( json ) {
        json.isOpen = false;
        super({
            mobileFilterIsOpen: false,
            displayedResources: json.resources || [],
            ...json
        });
        this.levelTagFilter = new TagFilterModel( json.resources, 'level');
        this.topicTagFilter = new TagFilterModel( json.resources, 'topic');
        this.durationTagFilter = new TagFilterModel( json.resources, 'duration');

        this.levelTagFilter.change.subscribe(() => this.updateDisplayedResources());
        this.topicTagFilter.change.subscribe(() => this.updateDisplayedResources());
        this.durationTagFilter.change.subscribe(() => this.updateDisplayedResources());
    }

    private updateDisplayedResources () {
        const filters = [this.topicTagFilter, this.levelTagFilter, this.durationTagFilter];
        this.displayedResources = this.resources.filter(r => filters.every(f => f.filteredResources.indexOf(r) >= 0));
    }

    public get resources(): ResourceViewModel[] {
        return this.get<ResourceViewModel[]>('resources', []);
    }

    public get name(): string {
        return this.get<string>('name');
    }


} 