import { Resolvable } from 'ln/components/Resolve';
import Model from 'ln/model/Model';

interface AssetModel {
    file_name: string;
    preset: string;
    ext: string;
}

export default class ImageViewModel extends Model implements Resolvable {

    image:AssetModel;

    public constructor( json ) {
        super( json );
    }

    public get caption(): string {
        return this.get<string>('caption');
    }

    public getViewName(): string{
        return 'ImageElement';
    }

} 