import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

import { PoolViewModel } from './PoolViewModel';
import { Pool as template } from '../../templates/components/pool';

export default class Pool extends View<PoolViewModel> {

    public constructor() {
        super({ template });
    }

    public onMobileFilterToggle() {
        const MobileFilter = this.node.querySelector('.MobileFilter');
    }

};
