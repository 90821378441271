import { Resolvable } from 'ln/components/Resolve';
import {Quiz, QuizInputState} from '@lernetz/lernfragen/components/quiz';
import * as visitors from '@lernetz/lernfragen/components/visitors';
import Model from 'ln/model/Model';
import { QuizVisitor } from '@lernetz/lernfragen/components/quiz';
import { canShowCorrectness } from '@lernetz/lernfragen/components/quiz/CanShowCorrectness';
import { ChoiceQuiz, ChoiceQuizVisitor } from '@lernetz/lernfragen/components/choice-quiz';
import { ClozeQuiz } from '@lernetz/lernfragen/components/cloze-quiz';
import { HotSpotQuiz } from '@lernetz/lernfragen/components/hot-spot-quiz';
import { DragAndDropQuiz } from '@lernetz/lernfragen/components/drag-and-drop-quiz';

export class QuizPresenterViewModel extends Model implements Resolvable {

    public constructor(quiz, title, text, posFeedback, negFeedback) {
        super({
            quiz,
            title,
            text,
            posFeedback,
            negFeedback,
            evaluationResult: '',
            canEvaluate: true,
        });
    }

    public quiz: Quiz;
    public title:string;
    public text:string;
    public posFeedback: string;
    public negFeedback: string;
    public evaluationResult: QuizInputState;
    public canEvaluate: boolean;

    public evaluate() {
        if (!this.canEvaluate) return;

        if (this.quiz instanceof ClozeQuiz) {
            const updatingFeedback = new visitors.ShowCorrectnessOfGivenAnswersQuizVisitor().visit(this.quiz);
            //...
            // updatingFeedback.unsubscribe();
        }
        else if (this.quiz instanceof HotSpotQuiz) {
            new visitors.ShowCorrectnessOfGivenAnswersQuizVisitor().visit(this.quiz);
            new visitors.ShowCorrectnessOfCorrectAnswersQuizVisitor().visit(this.quiz);
        }
        else if (this.quiz instanceof DragAndDropQuiz) {
            new visitors.ShowCorrectnessOfGivenAnswersQuizVisitor().visit(this.quiz);
        }
        else {
            new ShowCorrectnessOfAllAnswersQuizVisitor().visit(this.quiz);
            // Prinzipiell richtige Antworten anzeigen: 
            // new visitors.ShowCorrectnessOfGivenAnswersQuizVisitor().visit(this.quiz);
            // new visitors.ShowCorrectnessOfCorrectAnswersQuizVisitor().visit(this.quiz);
        }

        // // Feedback verbergen:
        // new visitors.ShowCorrectnessOfNoAnswersQuizVisitor().visit(this.quiz);

        this.quiz.change.filter(p => p.name === 'inputState').subscribe(() => this.evaluationResult = this.quiz.inputState);
        this.evaluationResult = this.quiz.inputState;

        this.canEvaluate = false;
    }

    public getColorClass(evaluationResult: QuizInputState) {
        switch (evaluationResult) {
            case 'none':
            case 'incomplete': return '-incomplete';
            case 'incorrect': return '-wrong';
            case 'correct': return '-correct';
        }
    }

    public getFeedbackText(evaluationResult: QuizInputState) {
        switch (evaluationResult) {
            case 'none':
            case 'incomplete': return '';
            case 'incorrect': return this.negFeedback;
            case 'correct': return this.posFeedback;
        }
    }

    public getFeedbackVisible(evaluationResult: QuizInputState) {
        return !!this.evaluationResult;
    }

    public getQuizIsCorrect(evaluationResult: QuizInputState) {
        switch (evaluationResult) {
            case 'none':
            case 'incomplete': 
            case 'incorrect': return false;
            case 'correct': return true;
        }
    }

    getViewName() {
        return 'QuizPresenter';
    }
}


class ShowCorrectnessOfAllAnswersQuizVisitor extends QuizVisitor<void> implements ChoiceQuizVisitor<void> {

    public visitChoiceQuiz(quiz: ChoiceQuiz): void {
        for (const choice of quiz.choices) {
            if (canShowCorrectness(choice)) {
                choice.showCorrectness = true;
            }
        }
    }
}