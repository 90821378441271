import View from '@lernetz/ts-lib/src/core/view/View';

import FrageTextViewModel from './FrageTextViewModel';
import {Title as template} from '../../../templates/components/content-elements/title';

export default class FrageText extends View<FrageTextViewModel> {

    public constructor() {
        super({ template });
    }

    protected init() {
        const { title } = this.data;
        this.node.innerHTML = `<h3 class="question content-margin-small">${title}</h3>`;
    }
};
