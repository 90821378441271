import { Resolvable } from 'ln/components/Resolve';
import Model from 'ln/model/Model';

export default class FrageTextViewModel extends Model implements Resolvable {


    public constructor(json: any) {
        super(json);
    }
    public get level(): string {
        return this.get<string>('level');
    }

    public get title(): string {
        return this.get<string>('title');
    }


    public getViewName(): string{
        return 'FrageText';
    }

} 