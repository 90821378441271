import Model from 'ln/model/Model';
import { Resolvable } from 'ln/components/Resolve';

export default class ToolTipViewModel extends Model implements Resolvable {


    public constructor( json ) {
        super( json );
    }

    public get tooltips(): any {
        return this.get<any>('tooltips');
    }

    public getViewName(): string{
        return 'ToolTip';
    }

}
