import { Resolvable } from 'ln/components/Resolve';
import { renderer } from 'ln/view/Renderer';
import Model from 'ln/model/Model';

export class SinglePageViewModel extends Model implements Resolvable {

    public constructor( json ) {
        super( json );
    }

    public get elements(): Resolvable[]{
        return this.get<Resolvable[]>('elements');
    }
    
    public get slug(): string {
        return this.get<string>('slug');
    }

    public get type(): string {
        return this.get<string>('type');
    }

    public getViewName(): string{
        return 'SinglePage';
    }

} 