import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

import ResourceViewModel from './ResourceViewModel';
import {ResourceDetail as template} from '../../templates/components/resources';

export default class ResourceDetail extends View<ResourceViewModel> {

    public constructor() {
        super({ template });
    }

    protected init() {
        const cover = this.node.querySelector('.cover');
        cover.setAttribute('style', "background-image: url(" + renderer.context.imagePath(this.data.cover, 'large', 'png') + ")");
    }
};
