import Lang from 'ln/lang/Lang';
import {renderer} from 'ln/view/Renderer';
import {scan} from 'ln/view/View';
import {setup, SetupConfig} from 'ln/setup/Setup';
import Request from 'ln/request/Request';

import {viewRegistrations} from './components/register';
import { registrations as choiceQuizRegistrations } from '@lernetz/lernfragen/components/choice-quiz';
import { registrations as dropDownQuizRegistrations } from '@lernetz/lernfragen/components/cloze-quiz';
import { registrations as hotSpotQuizRegistrations } from '@lernetz/lernfragen/components/hot-spot-quiz';
import { registrations as dragAndDropQuizRegistrations } from '@lernetz/lernfragen/components/drag-and-drop-quiz';

export function load(dataUri: string) {
    Request.get(dataUri).load().then(init);
}

function init(data: SetupConfig) {

    setup.init(data);
    renderer.context.setup = setup;

    Lang.add(setup.data('lang'));
    renderer.context.trans = function(key: string, opts?: { data?: any, condition?: number }) {
        return Lang.translate(key, opts);
    };

    renderer.context.detailURL = (lang:string, type:string, slug:string) => setup.route('detail', { lang, type, slug }).url();

	renderer.context.imagePath = function(image: { file_name: string }, preset: string = 'medium', ext: string = 'png') {
		if( !image ) return 'https://via.placeholder.com/233x150/F0F0F0/000000?text=Bild fehlt';
		return decodeURIComponent(setup.route('asset', { file_name: image.file_name, preset, ext }).url());
    }

    scan(document.body,
        choiceQuizRegistrations,
        dropDownQuizRegistrations,
        dragAndDropQuizRegistrations,
        hotSpotQuizRegistrations,
        viewRegistrations);
}
