import View from '@lernetz/ts-lib/src/core/view/View';

import ParagraphViewModel from './ParagraphViewModel';
import {Paragraph as template} from '../../../templates/components/content-elements/paragraph';

export default class Paragraph extends View<ParagraphViewModel> {

    public constructor() {
        super({ template });
    }

    protected init() {
        this.node.innerHTML = this.data.text;
        this.node.classList.add('content-margin');
    }
};
