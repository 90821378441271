import Model from 'ln/model/Model';

export default class TagViewModel extends Model {

    label: String;
    checked: boolean;

    constructor( label: string ) {
        super( { label, checked: false } );
    } 

}
