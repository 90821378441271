import { Resolvable } from 'ln/components/Resolve';
import Model from 'ln/model/Model';


export default class IFrameViewModel extends Model implements Resolvable {

    public constructor(json: any) {
        super(json);
    }

    public get caption(): string {
        return this.get<string>('caption');
    }

    public get url(): string {
        return this.get<string>('url');
    }

    public getViewName(): string{
        return 'IFrame';
    }

} 