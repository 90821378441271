import Model from '@lernetz/ts-lib/src/core/model/Model';
import ResourceViewModel from '../resources/ResourceViewModel';
import TagModel from '../tag/TagViewModel';

interface LabelTag {
    label:string,
    tagModel: TagModel,
    checked:boolean;
}

export default class TagFilterModel extends Model {

    tags: Array<TagModel>;
    resources: ResourceViewModel[];
    attributeName: string;
    filteredResources: ResourceViewModel[];

    constructor( resources: ResourceViewModel[], attributeName: string ) {
        super( { resources, attributeName, filteredResources: resources } );
        this.updateTags();
    }
    
    updateTags() {
        
        var items = this.resources.map( resource => { return resource[this.attributeName] } );
        var labels = [];
        items.forEach( item => {
            if( Array.isArray(item) ) {
                item.forEach( s => {
                    if( labels.indexOf( s ) == -1 ) labels.push( s );
                });
            } else if (typeof(item) == 'string') {
                if( labels.indexOf( item ) == -1 ) labels.push( item );
            }
        });
        this.tags = labels.map(label => new TagModel( label ));

        this.tags.forEach ( tag => {
            tag.change.filter( p => p.name == 'checked' ).subscribe(this.filter.bind(this))
        });

    }

    filter() {

        const filteredResources = this.resources.filter( resource => {
            const item = resource[this.attributeName];
            const checkedTagLabels = this.tags.filter(t => t.checked).map(t => t.label);
            if( checkedTagLabels.length ) {
                if( Array.isArray(item)) {
                    return checkedTagLabels.every(tagLabel => item.indexOf(tagLabel) >= 0);
                }
                else if( typeof item === 'string' ) {
                    return checkedTagLabels.every(tagLabel => item === tagLabel);
                }
            }
            else {
                return true;
            }
        });

        this.filteredResources = filteredResources;
    }
}
