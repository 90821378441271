import { Resolvable } from 'ln/components/Resolve';
import Model from 'ln/model/Model';

export default class ParagraphViewModel extends Model implements Resolvable {

    public constructor( json ) {
        super( json );
    }

    public get text(): string {
        return this.get<string>('text');
    }

    public getViewName(): string{
        return 'Paragraph';
    }

} 