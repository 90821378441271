import {node} from 'ln/node/Node';
import View from '@lernetz/ts-lib/src/core/view/View';

import ToolTipViewModel from './ToolTipViewModel';
import {ToolTip as template} from '../../../templates/components/content-elements/tool-tip';

export default class ToolTip extends View<ToolTipViewModel> {

    public toolTipArea;

    public constructor() {
        super({ template });
    }

    protected init() {

        this.toolTipArea = this.node.querySelector( '.ToolTipElementStage' );
        
        this.data.tooltips.forEach(area => {

            const areaNodeWrapper = node(`<div class="ToolTipArea"></div>`);
            const areaNode = <HTMLElement> areaNodeWrapper.native;
            areaNode.style.width = (area.width * 100).toFixed(2) + "%";
            areaNode.style.height = (area.height * 100).toFixed(2) + "%";
            areaNode.style.top = (area.top * 100).toFixed(2) + "%";
            areaNode.style.left = (area.left * 100).toFixed(2) + "%";
            
            const tooltipNodeWrapper = node(`<div class="ToolTipElement padding-box -hidden">${area.text}</div>`);
            const tooltipNode = <HTMLElement> tooltipNodeWrapper.native;

            const updateTooltip = (e: MouseEvent) => {
                const componentBounds = this.toolTipArea.getBoundingClientRect();
                const areaBounds = areaNode.getBoundingClientRect();
                const mouseOverArea = areaBounds.left <= e.clientX && e.clientX <= areaBounds.right &&
                                      areaBounds.top <= e.clientY && e.clientY <= areaBounds.bottom;
                tooltipNode.classList.toggle('-hidden', !mouseOverArea);
                tooltipNode.style.left = (e.clientX - componentBounds.left) + 'px';
                tooltipNode.style.top = (e.clientY - componentBounds.top) + 'px';
            }

            areaNode.addEventListener('mouseenter', e => updateTooltip(e));
            areaNode.addEventListener('mousemove', e => updateTooltip(e));
            areaNode.addEventListener('mouseleave', e => updateTooltip(e));

            tooltipNode.addEventListener('mouseenter', e => updateTooltip(e));
            tooltipNode.addEventListener('mousemove', e => updateTooltip(e));
            tooltipNode.addEventListener('mouseleave', e => updateTooltip(e));

            this.toolTipArea.appendChild(areaNode);
            this.toolTipArea.appendChild(tooltipNode);
            
        })

    }

};
