import Model from 'ln/model/Model';
import { PoolViewModel } from 'components/pool/PoolViewModel';
import { SinglePageViewModel } from 'components/singlepage/SinglePageViewModel';
import ResourceViewModel from 'components/resources/ResourceViewModel';
import {setup} from 'ln/setup/Setup';

export class AppViewModel extends Model {

    public get singlepages(): SinglePageViewModel[] {
        return setup.data('singlepages') || [];
    }
    public get languages(): Language[] {
        return setup.data('languages') || [];
    }
}


export class PoolAppViewModel extends AppViewModel {

    public get pool(): PoolViewModel {
        return setup.data('pool');
    }
}


export class DetailAppViewModel extends AppViewModel {

    public get resource(): ResourceViewModel | SinglePageViewModel {
        return setup.data('resource');
    }
}


interface Language {
    id: string;
    isActive: boolean;
    href: string;
}
