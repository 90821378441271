import View from '@lernetz/ts-lib/src/core/view/View';

import TitleViewModel from './TitleViewModel';
import {Title as template} from '../../../templates/components/content-elements/title';

export default class Title extends View<TitleViewModel> {

    public constructor() {
        super({ template });
    }

    protected init() {
        const { level, title } = this.data;
        // this.node.innerHTML = `<h${level} class="content-margin">${title}</h${level}>`;
        this.node.innerHTML = `<h2>${title}</h2>`;
    }
};
