import View from '@lernetz/ts-lib/src/core/view/View';
import { renderer } from '@lernetz/ts-lib/src/core/view/Renderer';

import ResourceViewModel from './ResourceViewModel';
import { Resource as template } from '../../templates/components/resources';

export default class ResourceDetail extends View<ResourceViewModel> {

    public constructor() {
        super({ template });
    }

    protected init() {
        const previewimage = this.node.querySelector('.previewimage');
        previewimage.setAttribute('style', "background-image: url(" + renderer.context.imagePath(this.data.previewimage, 'large', 'png') + ")");
    }
};
